import React from 'react';
import { Settings } from '../SettingPanel';
import bkg from './R8_only_bg_Video page.png'

const Loading = (props) => {
    
    function refresh() {
        window.location.reload();
    }

    function toLink() {
        window.location.href = Settings.redirectPath ;
    }

    return (
        <div>
            {/* <div class="top-logo">
                Company Logo
            </div> */}
            
            <img class="element-cert" src={bkg}/>
            <div class="container-text">
                <div class="top-text">
                    網頁跳轉中... <br/>
                    如果長時間沒有跳轉，請刷新重試
                </div>
                {/* <div class = "exist-container-btn">
                    <button class="button-29" role="button" onClick ={refresh}>刷新</button>
                    <button class="button-29" role="button" onClick ={toLink}>回到主頁</button>
                </div> */}
            </div>
    </div>
    );
}
export default Loading;