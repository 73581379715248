class SettingPanel {
    constructor() {
      fetch('/Setting.json') //test
      // fetch('/rnd/public/imageServer/Setting.json') //deployment
      .then((r) => r.json())
      .then(json  => {
        console.log(json);
        Object.keys(json).map(key =>{
          this[key] = json[key];
        })
      })
    }
  }
  
export let Settings = new SettingPanel();