import React from 'react';
import { Settings } from '../SettingPanel';
import bkg from './R8_only_bg_Video page.png'

const Unexist = (props) => {
    
    const url = Settings.imgPath + "R8_only_bg_Video_page.png"
    function toLink() {
        window.location.href = Settings.redirectPath ;
    }

    return (
        <div>
            {/* <div class="top-logo">
                Company Logo
            </div> */}

            <img class="element-cert" src={bkg}/>
            <div class="container-text">
                <div class="top-text">
                    網絡無法連接，請聯絡職員同事
                </div>
                <div class = "exist-container-btn">
                    <button class="button-29" role="button" onClick ={toLink}>回到主頁</button>
                </div>
            </div>
        </div>
    );
}
export default Unexist;