// import logo from './logo.svg';
import './App.css';
import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Settings } from './SettingPanel';

import CertDisplay from './component/CertDisplay';
import Unexist from './component/Unexist';
import Invalid from './component/Invalid';

var renderTimer;
function App() {
  // const [state, setState] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  
  useEffect(() => {
    clearInterval(renderTimer);
    renderTimer = setInterval(() => {
      if (Settings.routers) {
        console.log("Settings.routers", Settings.routers)
        // setState(!state);
        forceUpdate();
        clearInterval(renderTimer);
      } else {
        // console.log("call rerender", Settings.routers);
      }
    }, 200);
  }, [])

  return (
    <div className="App">
      {Settings?
        Settings.routers? 
          <Router>
            <Routes >
              {Settings.routers.map((path) => {
                return (
                  <Route path={path} element={<CertDisplay/>}/>
                )
              })}
              {/* <Route path="/cert/:id" element={<CertDisplay/>}/> */}
              <Route path='/welcome/:id' element={<Unexist/>}/>
              <Route path="*" element ={<Invalid/>}/>
            </Routes>
          </Router>
        : <></>
      : <></>
      }
    </div>
  );
}

export default App;
