import React from 'react';
import './component.css'
import bkg from './R8_only_bg_Video page.png'

import { Settings } from '../SettingPanel';
const Invalid = (props) => {

    console.log("Invalid");
    function toLink() {
        window.location.href = Settings.redirectPath ;
    }

    // console.log(Settings.version)
    return (
        <div class = "fullPage">
            <div>
                {/* <div class="top-logo">
                    Company Logo
                </div> */}
                
                <img class="element-cert" src={bkg}/>

                <div class="container-text">
                    <div class="top-text">
                        此頁面不存在
                    </div>
                    <div class = "exist-container-btn">
                        <button class="button-29" role="button" onClick ={toLink}>回到主頁</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Invalid;