import React from 'react';
import { saveAs } from 'file-saver'

import { Settings } from '../SettingPanel';
import './component.css'
import button from './R8_Cert_button.png'

const Exist = (props) => {

    const url = Settings.imgPath + props.id + ".png"
    function downloadCert() {
        saveAs(url, "cert-" + props.id + ".png")
    }

    function toLink() {
        window.location.href = Settings.redirectPath ;
    }

    return (
        <div>
            {/* <div class = "element-text">
                <div class="top-logo">
                    Company Logo
                </div>
                <div class="top-text">
                    感謝閣下參與活動，請下滑檢視您的參與狀。
                    <br/>
                    您亦可以下載參與狀以作紀念。
                </div>
                <div class = "exist-container-btn">
                    <button class="button-29" role="button" onClick ={downloadCert}>下載</button>
                    <button class="button-29" role="button" onClick ={toLink}>回到主頁</button>
                </div>
            </div> */}


            <div class="element-cert">
                <img class = "cert" src = {url}/>
                <div class = "container-button">
                    {/* <button class="button-more" role="button" onClick ={toLink}>立即查看更多</button> */}
                    <img class="button-more" role="button" onClick ={toLink} src = {button}/>
                </div>
            </div>


            {/* <div style={{height: "3vh"}}/> */}
        </div>
    );
}
export default Exist;