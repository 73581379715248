import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Settings } from '../SettingPanel';

import Exist from './Exist';
import Unexist from './Unexist';
import Loading from './Loading';
import './component.css'

const CertDisplay = () => {
    const { id } = useParams();
    const [imgState, setImgState] = useState(0);

    useEffect(() => {
        console.log("id updated");
        checkImage(Settings.imgPath + id + ".png")
    }, [id])

    async function checkImage(url) {
        console.log(url);
        const res = await fetch(url);
        console.log(res);
        const buff = await res.blob();
        console.log(buff);

        if (buff.type.startsWith('image/')) {
            setImgState(1);
        } else {
            setImgState(2);
        }
    }

    return (
        <div class ="fullPage">
            {imgState == 0? 
                <Loading/>
                : imgState == 1?
                    <Exist 
                        id = { id }
                    />
                    : <Unexist
                        id = { id }
                    />
            }
        </div>
    );
}
export default CertDisplay;